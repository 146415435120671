<template>
  <v-card flat class="segmented">
    <v-card-title>
      {{ $t('forms.new') }}
    </v-card-title>
    <v-card-text>
      <create-form
        v-model="newForm"
        :mode="'create'"
        @save="save"
        @create="create"
      ></create-form>
    </v-card-text>
  </v-card>
</template>

<script>
import createForm from '@/components/forms/create-form.vue';
import { mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    createForm
  },

  data: () => ({
    newForm: {
      id: uuidv4(),
      correlationId: uuidv4(),
      pages: [
        {
          id: uuidv4(),
          correlationId: uuidv4(),
          pageIndex: 1,
          elements: []
        }
      ]
    }
  }),

  async mounted() {
    const slug = this.$route.params.slug;
    await this.loadCustomSettings(slug);
  },

  methods: {
    ...mapActions({
      saveForm: 'forms/saveForm',
      loadCustomSettings: 'forms/loadCustomSettings'
    }),

    save() {
      this.saveForm({ data: this.newForm, isDraft: true });
    },

    create() {
      this.saveForm({ data: this.newForm, isDraft: false });
    }
  }
};
</script>
